import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

type IosButtonProps = {
  style?: ButtonProps;
  styles?: object;
  text: string;
  onClick?: () => void;
};

const IosButton = ({ text, style,onClick, styles }: IosButtonProps) => {
  return (
    <Button
      onClick={onClick}
      borderRadius="full"
      fontWeight="500"
      iconSpacing="4px"
      padding="6px 32px"
      height="auto"
      {...style}
      style={styles}
    >
      {text}
    </Button>
  );
};

export default IosButton;
