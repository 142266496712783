import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import ContactUsTemplate from "../components/global/ContactUsTemplate";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import SectionTitle from "../components/global/SectionTitle";
import { questions } from "../helpers/helpers";

const Faq = () => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });

  return (
    <Box>
      <Helmet>
        <title>Nocadis - FAQ</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Box pt={{ xs: "128px", lg: "64px" }}>
        <DarkModeSwitch />
        <SectionTitle
          style={{ marginTop: "24px" }}
          title={`Frequently asked questions `}
          subTitle={`It is a long established fact that a reader will be distracted by the readable content${breakline} of a page when looking at its layout. The point of using Lorem Ipsum is that it has a ${breakline}more-or-less normal distribution of letters, as opposed to using 

          `}
        />
        <Accordion
          allowToggle
          pb="100px"
          w={{ xs: "90%", md: "70%" }}
          m="0 auto"
          mt="40px"
        >
          {questions.map((question, index) => (
            <AccordionItem>
              <h2>
                <AccordionButton p="20px">
                  <Box flex="1" textAlign="left">
                    {question.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{question.answer}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
      <ContactUsTemplate />
    </Box>
  );
};

export default Faq;
