import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  keyframes,
  useBreakpointValue,
  useColorModeValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import SectionTitle from "../components/global/SectionTitle";
import AnotherServices from "../components/Home/AnotherServices";
import Contact from "../components/Home/Contact";
import Counters from "../components/Home/Counters";
import Mobiles from "../components/Home/Mobiles";
import { Link } from "react-router-dom";
import { FC } from "react";
import Websites from "../components/Home/Websites";
import Helmet from "react-helmet";
const Fade = require("react-reveal/Fade");

type HomeProps = {
  webDevRef: React.RefObject<HTMLInputElement>;
  mobileDevRef: React.RefObject<HTMLInputElement>;
  servicesRef: React.RefObject<HTMLInputElement>;
};

const Home: FC<HomeProps> = ({
  webDevRef,
  mobileDevRef,
  servicesRef,
}: HomeProps) => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });
  const spin = keyframes`
  0% { background-position: -15vw }
  25% {  background-position: 0vw}
  50% {  background-position: 15vw }
  75% {background-position: 0vw}
  100% {  background-position: -15vw }
`;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} 10s infinite linear`;

  return (
    <Box>
      <Helmet>
        <title>Nocadis - Software Development</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Box pt={{ xs: "128px", lg: "64px" }}>
        <Fade bottom>
          <DarkModeSwitch />
        </Fade>
        <SectionTitle
          title={`We're making IT solutions \n and things`}
          subTitle={`We are a new-generation software house. Our fully remote operations,${breakline} combined with an outstanding work culture, enable us to produce distinctive projects.${breakline} We pride ourselves on punctuality, quality, and the most competitive pricing.`}
        />
        <Flex
          alignItems="center"
          justifyContent="center"
          mt="40px"
          flexDirection={{ xs: "column", sm: "row" }}
          role="presentation"
        >
          <Fade bottom>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <Button
                colorScheme="primary"
                p="24px 0"
                leftIcon={<Image src="assets/rocket.svg" w="20px" />}
                me={{ xs: "0", sm: "8px" }}
                w="200px"
              >
                Get Started
              </Button>
            </Link>
          </Fade>
          <Fade bottom delay={300}>
            <Link to="/contact-us" style={{ textDecoration: "none" }}>
              <Button
                variant="outline"
                color={useColorModeValue("#343434", "white")}
                borderColor="primary.200"
                colorScheme="primary"
                p="24px 0"
                ms={{ xs: "0", sm: "8px" }}
                mt={{ xs: "16px", sm: "0" }}
                w="200px"
              >
                Contact Us
              </Button>
            </Link>
          </Fade>
        </Flex>
        <Box w={{ xs: "95%", md: "80%" }} m="0 auto" mt="70px" mb="30px">
          <object type="image/svg+xml" data="assets/devs.svg">
            svg-animation
          </object>
        </Box>

        <SectionTitle
          style={{ marginTop: "150px" }}
          title={`What we're doing?`}
          subTitle={`At Nocadis, we craft and design beautiful products of world-class quality. ${breakline}Let us know how we can assist you.`}
        />
        <div ref={webDevRef} />
        <Websites />
        <SectionTitle
          style={{ marginTop: "150px" }}
          title={`Other hand…`}
          subTitle={`We know that many of us use the phone very often, so we will also help you create something beautiful in the mobile version. ${breakline}Keep your vision always at hand.`}
        />
        <div ref={mobileDevRef} />
        <Mobiles />

        <SectionTitle
          style={{ marginTop: "150px" }}
          title={`We also have experience in…`}
          subTitle={`At Nocadis, we help not only with web and mobile applications. ${breakline}We try to support your projects in many areas.`}
        />
        <div ref={servicesRef} />
        <AnotherServices />
        <Fade bottom delay={500}>
          <Heading
            as="h1"
            fontSize={{ xs: "24px", sm: "36px", md: "44px" }}
            textAlign="center"
            mt="150px"
            mb="32px"
            animation={animation}
            p="0 12px"
            lineHeight="1.3"
            bg="linear-gradient(125deg, rgb(240, 0, 120) 30%, rgb(179, 109, 250) 65%)"
            style={{
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            For now we have done
          </Heading>
        </Fade>
        <Counters />
        <SectionTitle
          style={{
            marginTop: useBreakpointValue({ base: "100px", md: "150px" }),
          }}
          title={`Stay in touch `}
          subTitle={`Tell us how we can help and we will do everything we can to achieve it.`}
        />
        <Contact />
      </Box>
    </Box>
  );
};

export default Home;
