import { Box } from "@chakra-ui/react";
import SectionTitle from "../components/global/SectionTitle";

const Design = () => {
  return (
    <div>
      <Box
        mt={{ xs: "0", lg: "-84px" }}
        role="presentation"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "20px",
          zIndex: 999999,
          backgroundImage: "url('/assets/design.jpg')",
        }}
      >
        <div>
          <img
            src="/assets/nd-logo.svg"
            alt="nocadis logo"
            style={{
              maxWidth: "200px",
              width: "100%",
            }}
          />
          <img
            src="/assets/nd-txt.svg"
            alt="nocadis txt"
            style={{
              marginTop: "64px",
              maxWidth: "400px",
              width: "100%",
            }}
          />
        </div>
      </Box>
      <SectionTitle
        style={{ margin: "150px 32px", textAlign: "center" }}
        title={`About Nocadis Design`}
        subTitle={
          <Box
            style={{
              textAlign: "left",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <p style={{ marginBottom: "24px" }}>
              We're excited to announce the launch of nocadis.design, a beacon
              of innovation and professionalism in the design world, part of the
              prestigious nocadis family. We're not just a design studio; we're
              your strategic partner in creating exceptional visual narratives.
              Here's how we set the standard:
            </p>
            <p style={{ marginBottom: "24px" }}>
              - Expertise Across Industries: Our success stories span across
              medical, automotive, banking, and beauty sectors, showcasing our
              ability to understand and cater to diverse market needs.
            </p>
            <p style={{ marginBottom: "24px" }}>
              - Comprehensive Design Solutions: From UX/UI design for mobile and
              web applications to sophisticated web development, print
              materials, and custom graphics, we offer a full spectrum of
              services to elevate your brand.
            </p>
            <p style={{ marginBottom: "24px" }}>
              - Cutting-Edge Creativity: Our team consists of seasoned
              professionals who bring a wealth of experience, ensuring each
              project not only meets but exceeds expectations.
            </p>
            <p style={{ marginBottom: "24px" }}>
              - A Commitment to Excellence: At nocadis.design, professionalism
              is our cornerstone. We pride ourselves on delivering high-quality,
              impactful designs that drive success. Let's embark on a journey of
              transformation together. Your vision deserves the best.
            </p>
          </Box>
        }
      />
    </div>
  );
};

export default Design;
