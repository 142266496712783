import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  keyframes,
  useBreakpointValue,
  useColorModeValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { FC } from "react";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import SectionTitle from "../components/global/SectionTitle";
import { FiArrowRight } from "react-icons/fi";
import ContactUsTemplate from "../components/global/ContactUsTemplate";
import { Helmet } from "react-helmet";
const Fade = require("react-reveal/Fade");

const Portfolio = () => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });

  const spin = keyframes`
  0% { background-position: -5vw }
  25% {  background-position: 0vw}
  50% {  background-position: 5vw }
  75% {background-position: 0vw}
  100% {  background-position: -5vw }
`;

  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} 10s infinite linear`;

  return (
    <Box pt={{ xs: "128px", lg: "64px" }}>
      <Helmet>
        <title>Nocadis - Portfolio</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <DarkModeSwitch />
      <SectionTitle
        style={{ marginTop: "24px" }}
        title={`We have experience ${breakline}thanks to various projects`}
        subTitle={`We are proud that we could be part of these projects`}
      />

      <Fade bottom duration={2000}>
        <Heading
          animation={animation}
          w="70%"
          m="0 auto"
          mt="96px"
          bg="linear-gradient(125deg, rgb(240, 0, 120) 30%, rgb(179, 109, 250) 65%)"
          style={{
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          textAlign="center"
        >
          Our Clients
        </Heading>
      </Fade>
      <Grid
        templateRows={{ xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        templateColumns={{ xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        w={{ xs: "95%", md: "85%", lg: "75%" }}
        m="0 auto"
        gap={20}
        mt="32px"
        pb="32"
      >
        {portfolioItems.map((item, index) => {
          return (
            <GridItem className="animGridItem" key={index}>
              <PortfolioItem {...item} />
            </GridItem>
          );
        })}
      </Grid>
      <ContactUsTemplate />
    </Box>
  );
};

export default Portfolio;

interface PortfolioItemProps {
  url: string;
  title: string;
  subTitle: string;
  image: string;
  logo: string;
  logo_dark?: string;
  type: string;
}

const portfolioItems: PortfolioItemProps[] = [
  {
    image: "assets/mocks/dh_mock.png",
    logo: "assets/logos/dh.png",
    logo_dark: "assets/logos/dh_dark.png",
    subTitle:
      "The European go-to-place for scouting, buying, selling and storing supercars as well as an exclusive members club for passionate car aficionados.",
    title:
      "Development of Mobile App and Administration Panel for Drivers Hall.",
    type: "Mobile App & webapp",
    url: "https://apps.apple.com/pl/app/drivers-hall/id6450510954?l=pl",
  },
  {
    image: "assets/mocks/medical_mock.png",
    logo: "assets/logos/medical.png",
    logo_dark: "assets/logos/medical_dark.png",
    subTitle:
      "Medicalgorithmics Group specializes in AI-powered and noninvasive solutions for cardiac diagnostics. We have a clear goal: improving health through intelligent technologies.",
    title:
      "Design and Development of Corporate Website for Medicalgorithmics S.A.",
    type: "Design & website",
    url: "https://www.medicalgorithmics.com",
  },
  {
    image: "assets/mocks/mana_lake_mock.png",
    logo: "assets/logos/mana_lake.png",
    logo_dark: "assets/logos/mana_lake_dark.png",
    subTitle:
      "Mana Lake offers guests a unique way to explore the surroundings with mapped cycling routes highlighting interesting places worth visiting.",
    title: "Development of Mobile App for Mana Lake",
    type: "Design & Mobile app",
    url: "https://apps.apple.com/pl/app/mana-lake/id6468908616?l=pl",
  },
  {
    image: "assets/mocks/attpoint_mock.png",
    logo: "assets/logos/attpoint.svg",
    subTitle:
      "Attpoint is a marketplace designed for lawyers seeking substitutes for legal representation, streamlining the process of finding and offering legal services.",
    title:
      "Development of Attpoint Marketplace for Lawyers Seeking Legal Representation Substitutes",
    type: "webapp",
    url: "https://attpoint.pl",
  },
  {
    image: "assets/mocks/conmedic_mock.png",
    logo: "assets/logos/conmedic.png",
    subTitle:
      "Conmedic is a team of technology enthusiasts dedicated to finding the latest innovations in the field of cardiology.",
    title:
      "Website Development for Conmedic: A Team of Technology Enthusiasts Pursuing the Latest Innovations in Cardiology",
    type: "Design & website",
    url: "https://conmedic.pl",
  },
  {
    image: "assets/mocks/mv_officepark_mock.png",
    logo: "assets/logos/mv_officepark.png",
    logo_dark: "assets/logos/mv_officepark_dark.png",
    subTitle:
      "MV Office Park offers state-of-the-art office spaces designed to meet the needs of modern businesses.",
    title: "Website Development for MV Office Park",
    type: "Design & website",
    url: "https://mvofficepark.de/",
  },
  {
    image: "assets/mocks/daily_basics_mock.png",
    logo: "assets/logos/daily_basics.png",
    logo_dark: "assets/logos/daily_basics_dark.png",
    subTitle:
      "Daily Basics: Your go-to store for high-quality supplements and wellness products, designed to support a healthy and balanced lifestyle.",
    title: "Development of Online Store for Daily Basics",
    type: "Design & website",
    url: "https://dailybasics.store/",
  },
  {
    image: "assets/mocks/sin_by_mannei_mock.png",
    logo: "assets/logos/sin_by_mannei.png",
    logo_dark: "assets/logos/sin_by_mannei_dark.png",
    subTitle:
      "We developed a sophisticated and user-friendly e-commerce platform for Sin By Mannei, showcasing their luxury fashion products and enhancing the online shopping experience.",
    title: "E-commerce Website for Sin By Mannei",
    type: "Design & website",
    url: "https://shop.sinbymannei.com/",
  },
  {
    image: "assets/mocks/informermed_mock.png",
    logo: "assets/logos/informermed.png",
    subTitle:
      "We created a professional and informative corporate website for InformerMed, highlighting their innovative healthcare solutions and enhancing their online presence.",
    title: "Corporate Website for InformerMed",
    type: "Design",
    url: "#",
  },
  {
    image: "assets/mocks/terrano_mock.png",
    logo: "assets/logos/terrano.png",
    logo_dark: "assets/logos/terrano_dark.png",
    subTitle:
      "Providing modern bathroom solutions from top European manufacturers for over 30 years.",
    title: "Comprehensive Audit and Redesign of Terrano's Online Store",
    type: "Design",
    url: "#",
  },
  {
    image: "assets/mocks/skorglow_mock.png",
    logo: "assets/logos/skor.png",
    logo_dark: "assets/logos/skor_dark.png",
    subTitle:
      "Dr. Aneta Reszko combines cutting-edge technology with the latest scientific advances to ensure unparalleled results.",
    title: "Creation of Product Pages for Skorr Series by Dr. Aneta Reszko",
    type: "Design",
    url: "#",
  },
  {
    image: "assets/mocks/biocloner_mock.png",
    logo: "assets/logos/biocloner.png",
    logo_dark: "assets/logos/biocloner_dark.png",
    subTitle:
      "BioclonerHealth, an R&D company, addresses challenges both in developing new technologies and scaling them to industrial size.",
    title: "Development of Website and Online Store for BioclonerHealth",
    type: "Design & website",
    url: "#",
  },
  {
    image: "assets/mocks/belux_mock.png",
    logo: "assets/logos/belux.png",
    logo_dark: "assets/logos/belux_dark.png",
    subTitle:
      "Belux Decor specializes in providing high-quality decorative solutions, and we developed a tailored ERP system to enhance their operations.",
    title: "Design and Implementation of ERP System for Belux Decor",
    type: "Design & webapp",
    url: "#",
  },
  {
    image: "assets/mocks/agatat_mock.png",
    logo: "assets/logos/agatat.png",
    logo_dark: "assets/logos/agatat_dark.png",
    subTitle:
      "Agata Tabiszewska Notary Office offers professional notary services with a focus on reliability and client satisfaction.",
    title: "Website Design and Development for Agata Tabiszewska Notary Office",
    type: "Design",
    url: "#",
  },
];

const PortfolioItem: FC<PortfolioItemProps> = ({
  image,
  logo,
  logo_dark,
  subTitle,
  title,
  url,
  type,
}) => {
  const mainColor = useColorModeValue("black", "white");
  const logoImg = useColorModeValue(logo, logo_dark || logo);

  const content = (
    <div
      role="group"
      style={{
        padding: "16px",
      }}
    >
      <Box pos="relative">
        <Image
          src={image}
          w="100%"
          transitionDuration="0.3s"
          _groupHover={{
            transform: "scale(1.05)",
          }}
          p="8"
        />
        <Box
          pos="absolute"
          bottom="-2"
          right="-2"
          w="48px"
          transitionDuration="0.3s"
          h="48px"
          opacity={0}
          _groupHover={{
            opacity: 1,
            transform: "rotate(-45deg)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          border={`1px ${mainColor} solid`}
        >
          <FiArrowRight color={mainColor} />
        </Box>
      </Box>

      <Flex my="6" justify="space-between" align="center" gap="8px">
        <Image src={logoImg} h="30px" />
        <div style={{ fontWeight: 600, letterSpacing: "3px" }}>
          {type.toUpperCase()}
        </div>
      </Flex>
      <Heading as="h3" fontSize="24px" fontWeight="600">
        {title}
      </Heading>
      <Heading as="h4" fontSize="18px" fontWeight="normal" mt="4">
        {subTitle}
      </Heading>
    </div>
  );

  if (url === "#") return content;

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      style={{
        display: "block",
      }}
    >
      {content}
    </a>
  );
};
