import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { MdArrowForward } from "react-icons/md";
import IosButton from "../global/IosButton";
import ModalBox from "../global/ModalBox";
const Fade = require("react-reveal/Fade");
const RubberBand = require("react-reveal/RubberBand");

const AnotherServices = () => {
  const breakline = useBreakpointValue({ base: "", "2xl": "\n" });

  return (
    <Box>
      <Flex
        w={{ xs: "90%", md: "75%" }}
        m="0 auto"
        mt="64px"
        maxW="1200px"
        justifyContent="center"
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
      >
        <AnotherServiceCard
          title="UI&UX Design"
          bigDesc={
            <div>We're keeping our products in the best UX/UI design</div>
          }
          desc={`We're keeping it nice ${breakline}and simple following ${breakline}the latest trends.`}
          img={useColorModeValue("/assets/ui.svg", "assets/ui_dark.svg")}
          link={""}
        />
        <AnotherServiceCard
          title="Data Science"
          bigDesc={<div>We will solve your analitical problems</div>}
          desc={`We're here to solve ${breakline}your analitycal ${breakline}problems`}
          img={useColorModeValue(
            "/assets/data_science.svg",
            "assets/data_science_dark.svg"
          )}
          link={""}
        />
        <AnotherServiceCard
          bigDesc={
            <div>
              We know how your projects are important for You so we can secure
              them.
            </div>
          }
          title="Cybersecurity"
          desc={`We know how your projects ${breakline}are important for you ${breakline}so we can secure them.`}
          img="/assets/cybersecurity.svg"
          link={""}
        />
      </Flex>
      <Divider
        borderColor="secondary.300"
        m={{ xs: "0 auto", md: "70px auto" }}
        mb={{ xs: "80px", md: "70px" }}
        opacity="1"
        w={{ xs: "250px", sm: "300px" }}
      />
      <Flex
        w={{ xs: "90%", md: "75%" }}
        m="0 auto"
        mt="64px"
        maxW="1200px"
        justifyContent="center"
        alignItems="flex-end"
        direction={{ xs: "column", md: "row" }}
      >
        <AnotherServiceCard
          title="Graphics Things"
          bigDesc={
            <div>
              We can make beautiful graphics in different formats (.png .svg .xd
              .psd .jpg)
            </div>
          }
          desc={`We can make a lot${breakline}of nice graphics things${breakline}in a lot of formats`}
          img={useColorModeValue(
            "assets/graphic.svg",
            "assets/graphic_dark.svg"
          )}
          link={""}
        />
        <AnotherServiceCard
          bigDesc={
            <div>
              Every production process needs a good organization of work. We can
              help.
            </div>
          }
          title="ERP Platforms"
          desc={`Every production process ${breakline}needs a good organization ${breakline}of work. We can help.`}
          img={useColorModeValue("assets/erp.svg", "assets/erp.svg")}
          link={""}
        />
        <AnotherServiceCard
          bigDesc={
            <div>
              It's time to use new ways of learning and we have got solutions
              for it.
            </div>
          }
          title="E-learning"
          desc={`It's time to use new ways ${breakline}of learning and we have got ${breakline}solutions for them.`}
          img={useColorModeValue(
            "assets/elearning.svg",
            "assets/elearning_dark.svg"
          )}
          link={""}
        />
      </Flex>
    </Box>
  );
};

type AnotherServiceCardProps = {
  title: string;
  desc: string;
  bigDesc: string | JSX.Element;
  img: string;
  link: string;
};

const AnotherServiceCard: FC<AnotherServiceCardProps> = ({
  title,
  desc,
  bigDesc,
  img,
  link,
}: AnotherServiceCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box m={{ xs: "0", md: "0 64px" }} mb={{ xs: "96px", md: "0" }} w="100%">
      <ModalBox
        isOpen={isOpen}
        title={title}
        body={bigDesc}
        imgUrl={img}
        onClose={onClose}
      />
      <Image src={img} height="100px" display="block" m="0 auto" />
      <Heading
        as="h4"
        fontSize="24px"
        textAlign="center"
        mt="32px"
        p={{ xs: "0 16px", sm: "0" }}
      >
        <Fade bottom cascade>
          {title}
        </Fade>
      </Heading>
      <Heading
        as="h6"
        fontSize="15.5px"
        textAlign="center"
        mt="12px"
        fontWeight="400"
        lineHeight="1.35"
        color="#9D9D9D"
        whiteSpace="pre-wrap"
        p={{ xs: "0 16px", sm: "0" }}
      >
        {desc}
      </Heading>
      <Fade bottom delay={500}>
        <IosButton
          onClick={() => onOpen()}
          text="Read More"
          style={{
            margin: "0 auto",
            display: "block",
            mt: "24px",
            rightIcon: <MdArrowForward size={20} />,
            colorScheme: "primary",
          }}
          styles={{ display: "flex", padding: "8px 24px" }}
        />
      </Fade>
    </Box>
  );
};

export default AnotherServices;
