import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import ScrollLock from "react-scrolllock";
import { menu } from "../../helpers/helpers";
import useScrollDirection from "../../hooks/useScrollDirection";
const Fade = require("react-reveal/Fade");
const MobileMenu = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const scrollDirection = useScrollDirection();

  const enterAnim = keyframes`
  from { transform: translate(-100%, 0px);}
  to { transform: translate(0px, 0px); }
`;

  const enterAnimation = `${enterAnim} 0.75s ease 0s 1 normal forwards running`;

  const exitAnim = keyframes`
    from { transform: translate(0px, 0px); }
    to {  transform: translate(100%, 0px);}
  `;

  const [exitAnimation, setexitAnimation] = useState(
    "0s ease 0s 1 normal forwards running"
  );

  return (
    <Box w="100%" display={{ xs: "block", lg: "none" }}>
      <ScrollLock isActive={isOpen} accountForScrollbars={true} />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p="16px"
        position="fixed"
        w="100%"
        zIndex="999"
        top={scrollDirection == "down" ? "-70px" : "0px"}
        transitionDuration=".3s"
        boxShadow={`0 4px 2px -2px ${useColorModeValue("#eee", "#121212")}`}
        bg={useColorModeValue("#fff", "#121212")}
        h="70px"
      >
        <Link to="/">
          <Image src="/assets/nocadis_logo.svg" maxHeight="45px" />
        </Link>
        <Hamburger
          size={24}
          toggled={isOpen}
          toggle={setOpen}
          onToggle={() => {
            setexitAnimation(
              `${exitAnim} 0.75s ease 0s 1 normal forwards running`
            );
            setOpen(!isOpen);
          }}
        />
      </Flex>
      <Flex
        position="fixed"
        w="100%"
        direction="column"
        justifyContent="space-evenly"
        zIndex="999"
        top="70px"
        h={"calc(100vh - 70px)"}
        opacity={isOpen ? 1 : 0.5}
        transform="translate(100%, 0px)"
        animation={isOpen ? enterAnimation : exitAnimation}
        bg={useColorModeValue("#fff", "#121212")}
        transitionDuration="1s"
      >
        <Flex direction="column" justifyContent="space-around" h="70%">
          {menu.map((menuItem, index) =>
            menuItem.name !== "Contact Us" ? (
              <Link
                to={menuItem.url}
                onClick={() => setOpen(false)}
                key={index}
                className="mobileMenuLink"
              >
                {isOpen && (
                  <Fade cascade left delay={300}>
                    {menuItem.name}
                  </Fade>
                )}
              </Link>
            ) : (
              <Link
                to="/contact-us"
                onClick={() => setOpen(false)}
                style={{ textDecoration: "none", margin: "0 auto" }}
              >
                <Button w="200px" colorScheme="primary" m="0 auto">
                  Contact Us
                </Button>
              </Link>
            )
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default MobileMenu;
