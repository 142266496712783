import React, { FC, ForwardedRef, Ref } from "react";
import {
  Flex,
  Box,
  Heading,
  Image,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { menu } from "../../helpers/helpers";
import MobileMenu from "./MobileMenu";
import { Link, useLocation } from "react-router-dom";

const Nav: FC = () => {
  let color = useColorModeValue("#343434", "#ffffff");
  const location = useLocation();

  const isDesign = location.pathname === "/design";

  return (
    <>
      <MobileMenu />
      <Flex
        pt="32px"
        ps="32px"
        pe="32px"
        alignItems="center"
        display={{ xs: "none", lg: "flex" }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <HStack>
            <Image
              src={
                isDesign ? "/assets/nd-logo.svg" : "/assets/nocadis_logo.svg"
              }
              maxWidth="160px"
              ms="16px"
            />
          </HStack>
        </Link>
        <HStack as="ul" flex="1" justifyContent="flex-end" pr="24px">
          {menu.map((menuItem, index) => (
            <Link
              to={menuItem.url}
              style={{
                listStyleType: "none",
                cursor: "pointer",
                marginInlineStart: "0 !important",
                marginLeft: "0 !important",
              }}
            >
              <Box
                _hover={{ pb: "8px" }}
                transitionDuration=".2s"
                role={"group"}
              >
                <Heading
                  color={isDesign ? "#ffffff" : color}
                  fontWeight="500"
                  fontSize="16px"
                  key={index}
                  p="0 20px"
                  pb="4px"
                >
                  {menuItem.name}
                </Heading>
                <Box
                  w="0px"
                  m="0 auto"
                  h="1px"
                  bg={isDesign ? "#ffffff" : color}
                  _groupHover={{ w: "70%" }}
                  transitionDuration=".2s"
                ></Box>
              </Box>
            </Link>
          ))}
        </HStack>
      </Flex>
    </>
  );
};

export default Nav;
