export const menu = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Services",
    url: "/services",
  },
  {
    name: "Portfolio",
    url: "/portfolio",
  },
  {
    name: "Design",
    url: "/design",
  },
  {
    name: "About Us",
    url: "/about-us",
  },
  {
    name: "Contact Us",
    url: "/contact-us",
  },
];

export const questions = [
  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },

  {
    question: "  Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
    answer:
      "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.ncididunt ut labore et dolore magna aliqua.",
  },
];
