import { Box,useColorModeValue} from "@chakra-ui/react";
import React, { FC } from "react";

const InfoCard: FC = ({ children }) => {
  return (
    <Box
      bg={useColorModeValue("white","#222")}
      borderRadius="15px"
      pt="32px"
      pl="28px"
      pr="32px"
      pb="20px"
      height="100%"
      display="flex"
      flexDirection='column'
      alignItems="flex-start"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};

export default InfoCard;
