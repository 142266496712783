import {
  Box,
  Flex,
  Heading,
  keyframes,
  Link,
  useBreakpointValue,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import TypewriterComponent from "typewriter-effect";
import ContactForm from "../components/global/ContactForm";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import Footer from "../components/global/Footer";
import IosButton from "../components/global/IosButton";
import MacosView from "../components/global/MacosView";
import SectionTitle from "../components/global/SectionTitle";
const Fade = require("react-reveal/Fade");

const ContactUs = () => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });
  const spin = keyframes`
  0% { background-position: -15vw }
  25% {  background-position: 0vw}
  50% {  background-position: 15vw }
  75% {background-position: 0vw}
  100% {  background-position: -15vw }
`;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} 10s infinite linear`;
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
      privacyPolicyCheck: false,
    },
  });

  const onSubmit = (data: any) => console.log(data);

  return (
    <Box>
      <Helmet>
        <meta
          name="description"
          content="Nocadis is a small team developing websites, mobile apps, UX/UI designs and more. We’re always wanna creating nice things and support our partners."
        />
      </Helmet>
      <Box pt={{ xs: "128px", lg: "64px" }} mb="64px">
        <Fade bottom>
          <DarkModeSwitch />
        </Fade>
        <SectionTitle
          title={`Keep in touch \n with us`}
          subTitle={`Call or write and we will help you as soon as possible.`}
        />
        <MacosView style={{ mt: "64px" }}>
          <Box>
            <Heading
              as="h1"
              animation={animation}
              w="90%"
              textAlign="center"
              m="0 auto"
              ml={{ xs: "auto", lg: "32px" }}
              mt="32px"
              mb="32px"
            >
              <TypewriterComponent
                options={{
                  loop: true,
                  wrapperClassName: "customWrapperTypewriter",
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("Keep in ")
                    .typeString("Touch")
                    .pauseFor(3000)
                    .deleteChars(5)
                    .typeString("Contact")
                    .pauseFor(3000)
                    .deleteChars(7)
                    .start();
                }}
              />
            </Heading>
          </Box>

          <Flex
            direction={{ xs: "column", lg: "row" }}
            justifyContent="space-evenly"
          >
            <Fade bottom delay={500}>
              <Link
                href="tel:+48 606 27 77 97"
                style={{ textDecorationColor: "transparent" }}
              >
                <IosButton
                  text="+48 606 27 77 97"
                  style={{
                    mt: { xs: "12px", lg: 0 },
                    isFullWidth: true,
                    iconSpacing: "8px",
                    leftIcon: <FiPhone size={24} />,
                    colorScheme: "primaryDark",
                    p: "8px 32px",
                    color: "primary.500",
                  }}
                />
              </Link>
            </Fade>
            <Fade bottom delay={1000}>
              <Link
                href="mailto:jpiec@nocadis.com"
                isExternal
                style={{ textDecorationColor: "transparent" }}
              >
                <IosButton
                  text="jpiec@nocadis.com"
                  style={{
                    mt: { xs: "12px", lg: 0 },
                    isFullWidth: true,
                    iconSpacing: "12px",
                    textDecoration: "none !important",
                    leftIcon: <FiMail size={24} />,
                    colorScheme: "primaryDark",
                    p: "8px 32px",
                    color: "primary.500",
                  }}
                />
              </Link>
            </Fade>
            <Fade bottom delay={1500}>
              <Link
                isExternal={true}
                href="https://goo.gl/maps/ztoNcBt5ZoEgMU6J6"
                style={{ textDecorationColor: "transparent" }}
              >
                <IosButton
                  text="Zambrowska 29 Poznań"
                  style={{
                    mt: { xs: "12px", lg: 0 },
                    isFullWidth: true,
                    iconSpacing: "8px",
                    leftIcon: <FiMapPin size={24} />,
                    colorScheme: "primaryDark",
                    p: "8px 32px",
                    color: "primary.500",
                  }}
                />
              </Link>
            </Fade>
          </Flex>
          <Heading
            as="h1"
            animation={animation}
            w="90%"
            textAlign="center"
            m="0 auto"
            ml={{ xs: "auto", lg: "32px" }}
            mt="64px"
            mb="16px"
          >
            Contact Form
          </Heading>
          <Box w={{ xs: "100%", lg: "90%" }} m="0 auto">
            <ContactForm />
          </Box>
        </MacosView>
      </Box>
    </Box>
  );
};

export default ContactUs;
