import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const breakpoints = createBreakpoints({
  xs:'0px',
  sm: "560px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
});

const Button = {
  variants: {
    solid: {
      color: "white",
    },
  },
};

const Switch = {
  baseStyle: ({ colorMode }: { colorMode: any }) => ({
    track: {
      bg: colorMode === "dark" ? "#454545" : "#e4e4e4",
      _checked: {
        bg: "#486df0",
      },
      _focus: {
        boxShadow: "none",
      },
    },
  }),
};

const Checkbox = {
  baseStyle: ({ colorMode }: { colorMode: any }) => ({
    control: {
      borderColor: colorMode === "dark" ? "#565656" : "#dedede",
      _checked: {
        bg: colorMode === "dark" ? "#486df0" : "#007AFF",
        color: "white",
        borderColor: colorMode === "dark" ? "#486df0" : "#007AFF",
        _hover: {
          bg: colorMode === "dark" ? "#3051c7" : "#2750e6",
          borderColor: colorMode === "dark" ? "#3051c7" : "#2750e6",
        },
      },
    },
  }),
};

const Input = {
  baseStyle: ({ colorMode }: { colorMode: any }) => ({
    field: {
      bg: "transparent",
      borderColor: colorMode === "dark" ? "#293347" : "#eee",
      borderWidth: 2,
      fontWeight: "500",
      ":focus": {
        borderColor: "#486df0",
      },
    },
  }),

  sizes: {},
  variants: {},
  defaultProps: {
    variant: null, // null here
  },
};

const fonts = {
  body: `Poppins`,
  heading: `Poppins`,
};

const colors = {
  primary: {
    100: "#d1dbff",
    200: "#486df0",
    300: "#3b60e3",
    400: "#3051c7",
    500: "#007AFF",
    600: "#2750e6",
    700: "#163dc9",
    800: "#051a66",
    900: "#020f3b",
  },

  secondary: {
    100: "#e4e4e4",
    200: "#cdcdcd",
    300: "#aaaaaa",
    400: "#898989",
    500: "#565656",
    600: "#454545",
    700: "#343434",
    800: "#232323",
    900: "#121212",
  },

  primaryLight:{
    200:"#2f363d",
    300:"#333b42",
    400:"#39424a",
    500:"#e6f0ff",
    600:"#e0ecff",
    700:"#d4e5ff",
  },
  primaryDark:{
    200:"#272727",
    300:"#242424",
    400:"#202020",
    500:"#dfe6f0",
    600:"#d8dee8",
    700:"#d3ddeb",
  }
};

const theme = extendTheme({
  config,
  fonts,
  breakpoints,  
  styles: {
    global: (props: any) => ({
      "html, body": {
        color: props.colorMode === "dark" ? "white" : "#343434",
        a: props.colorMode === "dark" ? "white" : "#343434",
        bg: props.colorMode === "dark" ? "#121212" : "white",
      },
    }),
  },
  colors,
  components: {
    Button,
    Switch,
    Checkbox,
    Input,
  },
});

export default theme;
