import { Grid, Box, GridItem, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
const Counters = () => {
  return (
    <Grid
      templateRows={{
        xs: "repeat(4, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(1, 1fr)",
      }}
      templateColumns={{
        xs: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(4, 1fr)",
      }}
      maxW="800px"
      m="0 auto"
      mb={{ xs: "0", md: "100px" }}
    >
      <Counter counter={40} title="HAPPY CLIENTS" />
      <Counter counter={100} title="PROJECTS MADE" />
      <Counter counter={500} title="COFFEE CUPS" />
      <Counter counter={2000} title="HOURS CODED" />
    </Grid>
  );
};

type ConterProps = {
  title: string;
  counter: number;
};

const Counter = ({ title, counter }: ConterProps) => {
  const [ref, inView] = useInView({ triggerOnce: true });
  return (
    <GridItem ref={ref} m={{ xs: "20px 0" }}>
      <CountUp
        start={0}
        end={inView ? counter : 0}
        duration={3}
        prefix="+"
        style={{
          display: "block",
          textAlign: "center",
          fontWeight: "800",
          fontSize: "40px",
        }}
      />
      <Text
        textAlign="center"
        color={useColorModeValue("#000", "#FFF")}
        fontWeight="500"
        mt="1px"
      >
        {title}
      </Text>
    </GridItem>
  );
};

export default Counters;
