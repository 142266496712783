import React from 'react'
import { Box, HStack, Switch, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { IoIosMoon } from 'react-icons/io'
const DarkModeSwitch = () => {

    const { colorMode, toggleColorMode } = useColorMode()

    const handleDarkModeChanged = () => {
        toggleColorMode()
    }


    return (
        <HStack justifyContent='center' alignItems='center'>
            <Box bg={useColorModeValue('secondary.100', 'secondary.600')} p='2px' borderRadius='full' mb="2px">
                <IoIosMoon size={22} />
            </Box>
            <Switch onChange={handleDarkModeChanged} isChecked={colorMode === "dark"} />
        </HStack>

    )
}

export default DarkModeSwitch
