import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC } from "react";
const Fade = require("react-reveal/Fade");
type GoingRowProps = {
  style: FlexProps;
  image: string;
  title: string;
  subTitle: string;
};

const GoingRow: FC<GoingRowProps> = ({
  style,
  image,
  subTitle,
  title,
}: GoingRowProps) => {
  return (
    <Fade bottom>
      <Flex
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        width={{ xs: "90%", lg: "75%", xl: "60%" }}
        m={{ xs: "100px auto", md: "32px auto" }}
        {...style}
      >
        <Image
          src={image}
          h="250px"
          w={{ xs: "90%", md: "auto" }}
          m={{ xs: "16px auto", md: "32px 32px" }}
        />
        <Box m="0 32px">
          <Heading as="h5" fontSize="28px">
            {title}
          </Heading>
          <Text mt="12px" whiteSpace="pre-wrap">
            {subTitle}
          </Text>
        </Box>
      </Flex>
    </Fade>
  );
};

export default GoingRow;
