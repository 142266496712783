import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  Image,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import "../../styles/mockups/dist/devices.css";
import { IoIosWifi, IoIosBatteryFull } from "react-icons/io";
import {
  MdOutlineSignalCellularAlt,
  MdBatteryStd,
  MdSignalCellular3Bar,
  MdSignalWifi2Bar,
  MdArrowForward,
} from "react-icons/md";
import InfoCard from "../global/InfoCard";
import IosButton from "../global/IosButton";
import ModalBox from "../global/ModalBox";

const Fade = require("react-reveal/Fade");

const getFormattedTime = () => {
  const date = new Date();
  const timeComponents = [date.getHours(), date.getMinutes()];
  return timeComponents
    .map((component) => {
      const pad = component < 10 ? "0" : "";
      return pad + component;
    })
    .join(":");
};

const Mobiles = () => {
  return (
    <Box w={{ xs: "95%", lg: "80%" }} margin="0 auto" mt="100px">
      <Flex
        justifyContent="space-evenly"
        direction={{ xs: "column", lg: "row" }}
        alignItems={{ xs: "center", lg: "flex-start" }}
      >
        <IPhone />
        <Samsung />
      </Flex>
    </Box>
  );
};

const Samsung = () => {
  const androidModal = useDisclosure();

  const breakline = useBreakpointValue({ base: "", sm: "\n" });

  const marginT = useBreakpointValue({ base: "50px", lg: "150px" });

  return (
    <div className="device device-galaxy-s8" style={{ marginTop: marginT }}>
      <ModalBox
        isOpen={androidModal.isOpen}
        title={"Android Apps"}
        body={
          "We  make apps also for Android Operating Systems. Our applications will help you to run your business, improve the things you need"
        }
        imgUrl={useColorModeValue(
          "assets/android_apps.svg",
          "assets/android_apps_dark.svg"
        )}
        onClose={androidModal.onClose}
      />

      <div
        className="device-frame"
        style={{
          borderColor: useColorModeValue("#cfcfcf", "#555"),
          boxShadow: useColorModeValue(
            "inset 0 0 0 2px #9c9c9c",
            "inset 0 0 0 2px #565656"
          ),
        }}
      >
        <div
          className="device-content"
          style={{ background: useColorModeValue("#f5f5f5", "#303030") }}
        >
          <Flex pt="8px" justifyContent="space-between">
            <Text ps="16px" fontSize="14px" fontWeight="600">
              {getFormattedTime()}
            </Text>
            <HStack pe="12px" justifyContent="center" alignItems="center">
              <MdSignalCellular3Bar size={18} style={{ margin: "0 3px" }} />
              <MdSignalWifi2Bar size={18} style={{ margin: "0 2px" }} />
              <MdBatteryStd size={18} style={{ margin: "0px" }} />
            </HStack>
          </Flex>
          <Box p="0 16px">
            <Heading as="h4" mt="56px" fontSize={{ xs: "26px", sm: "30px" }}>
              <Flex>
                <Fade left>
                  <Image
                    src="assets/mobile_icon2.svg"
                    w="32px"
                    h="32px"
                    mr="12px"
                  />
                </Fade>
                <Fade cascade left>
                  Android Apps
                </Fade>
              </Flex>
            </Heading>
            <Fade bottom>
              <Heading
                as="h6"
                mt="8px"
                fontSize="16px"
                fontWeight="400"
                color="secondary.400"
                mb="32px"
                lineHeight="1.3"
              >
                We're also making apps for android {breakline}
                operating system
              </Heading>
            </Fade>

            <Box flex="1" mb="16px">
              <InfoCard>
                <Box>
                  <Heading as="h4" fontSize="20px" fontWeight="600">
                    Great apps tailored to your requirements
                  </Heading>
                  <Text
                    fontSize="15.5px"
                    fontWeight="400"
                    color="#9D9D9D"
                    mt="6px"
                    whiteSpace="pre-wrap"
                  >
                    Our apps help you improve things which you want to improve
                  </Text>
                  <IosButton
                    text="Read More"
                    onClick={() => androidModal.onOpen()}
                    style={{
                      display: "block",
                      mt: "24px",
                      color: "primary.500",
                      rightIcon: <MdArrowForward size={20} />,
                      colorScheme: "primaryLight",
                    }}
                    styles={{ display: "flex", padding: "8px 24px" }}
                  />
                </Box>
              </InfoCard>
            </Box>
            <Box>
              <InfoCard>
                <Image
                  src={useColorModeValue(
                    "assets/time.svg",
                    "assets/time_dark.svg"
                  )}
                  height="90px"
                />

                <Heading as="h4" fontSize="20px" fontWeight="600" mt="32px">
                  Save more and more time with our apps
                </Heading>
                <Text
                  fontSize="15.5px"
                  fontWeight="400"
                  color="#9D9D9D"
                  mt="6px"
                  mb="16px"
                  whiteSpace="pre-wrap"
                >
                  We promise, that you will save more time using our apps
                </Text>
              </InfoCard>
            </Box>
          </Box>
        </div>
      </div>
      <div className="device-stripe"></div>
      <div className="device-header"></div>
      <div className="device-sensors"></div>
      <div
        className={useColorModeValue("device-btns", "device-btns-dark")}
      ></div>
      <div
        className={useColorModeValue("device-power", "device-power-dark")}
      ></div>
    </div>
  );
};

const IPhone = () => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });
  const iosModal = useDisclosure();

  return (
    <div className="device device-iphone-x">
      <ModalBox
        isOpen={iosModal.isOpen}
        title={"IOS Apps"}
        body={
          "We make Apps for iOS Operating Systems. Also our apps can run natively on both platforms"
        }
        imgUrl={useColorModeValue(
          "assets/ios_apps.svg",
          "assets/ios_apps_dark.svg"
        )}
        onClose={iosModal.onClose}
      />
      <div
        className="device-frame"
        style={{
          boxShadow: useColorModeValue(
            "inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4",
            "inset 0 0 2px 2px #555, inset 0 0 0 7px #555"
          ),
        }}
      >
        <div
          className="device-content"
          style={{ background: useColorModeValue("#f5f5f5", "#303030") }}
        >
          <Flex pt="8px" justifyContent="space-between">
            <Text ps="32px" fontSize="14px" fontWeight="600">
              {getFormattedTime()}
            </Text>
            <HStack pe="12px" justifyContent="center" alignItems="center">
              <MdOutlineSignalCellularAlt
                size={20}
                style={{ margin: "0 2px" }}
              />
              <IoIosWifi size={20} style={{ margin: "0 3px" }} />
              <IoIosBatteryFull size={20} style={{ margin: "0 2px" }} />
            </HStack>
          </Flex>

          <Box p="0 16px">
            <Heading as="h4" mt="100px" fontSize={{ xs: "26px", sm: "30px" }}>
              <Flex alignItems="center">
                <Fade left>
                  <Image
                    src="assets/mobile_icon.svg"
                    w="32px"
                    h="32px"
                    mr="12px"
                  />
                </Fade>
                <Fade cascade left>
                  We make iOS and
                </Fade>
              </Flex>
            </Heading>
            <Fade bottom>
              <Heading
                as="h6"
                mt="8px"
                fontSize="16px"
                fontWeight="400"
                color="secondary.400"
                mb="32px"
                lineHeight="1.3"
              >
                Good looking app is key factor in your business growth
              </Heading>
            </Fade>

            <Box flex="1" mb="16px">
              <InfoCard>
                <Box>
                  <Heading as="h4" fontSize="20px" fontWeight="600">
                    Clean apps to improve your opportunities
                  </Heading>
                  <Text
                    fontSize="15.5px"
                    fontWeight="400"
                    color="#9D9D9D"
                    mt="6px"
                    whiteSpace="pre-wrap"
                  >
                    Our apps help you to automate parts of your work
                  </Text>
                  <IosButton
                    text="Read More"
                    onClick={() => iosModal.onOpen()}
                    style={{
                      display: "block",
                      mt: "24px",
                      color: "primary.500",
                      rightIcon: <MdArrowForward size={20} />,
                      colorScheme: "primaryLight",
                    }}
                    styles={{ display: "flex", padding: "8px 24px" }}
                  />
                </Box>
              </InfoCard>
            </Box>
            <Box>
              <InfoCard>
                <Image
                  src={useColorModeValue(
                    "assets/time_2.svg",
                    "assets/time_2_dark.svg"
                  )}
                  height="120px"
                />

                <Heading as="h4" fontSize="20px" fontWeight="600" mt="32px">
                  Make things faster and faster...
                </Heading>
                <Text
                  fontSize="15.5px"
                  fontWeight="400"
                  color="#9D9D9D"
                  mt="6px"
                  mb="16px"
                  whiteSpace="pre-wrap"
                >
                  Since today your company will operate faster
                </Text>
              </InfoCard>
            </Box>
          </Box>
        </div>
      </div>
      <div className="device-stripe"></div>
      <div className="device-header"></div>
      <div className="device-sensors"></div>
      <div
        className={useColorModeValue("device-btns", "device-btns-dark")}
      ></div>
      <div
        className={useColorModeValue("device-power", "device-power-dark")}
      ></div>
    </div>
  );
};

export default Mobiles;
