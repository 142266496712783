import React, { createRef } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/global/Navbar";
import AboutUs from "./pages/AboutUs";
import Portfolio from "./pages/Portfolio";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import Footer from "./components/global/Footer";
import Faq from "./pages/Faq";
import Design from "./pages/Design";

function App() {
  const webDevRef: React.RefObject<HTMLInputElement> = React.createRef();
  const mobileDevRef: React.RefObject<HTMLInputElement> = React.createRef();
  const servicesRef: React.RefObject<HTMLInputElement> = React.createRef();

  return (
    <div>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              webDevRef={webDevRef}
              mobileDevRef={mobileDevRef}
              servicesRef={servicesRef}
            />
          }
        />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/design" element={<Design />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="*" element={<div>Nothing here</div>} />
      </Routes>
      <Footer
        webDevRef={webDevRef}
        mobileDevRef={mobileDevRef}
        servicesRef={servicesRef}
      />
    </div>
  );
}

export default App;
