import React, { FC, useRef, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useColorModeValue,
  Image,
  useColorMode,
  useBreakpointValue,
  Progress,
  useDisclosure,
  Button,
  List,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import MacosView from "../../components/global/MacosView";
import InfoCard from "../../components/global/InfoCard";
import IosButton from "../../components/global/IosButton";
import { FiCheck } from "react-icons/fi";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useInView } from "react-intersection-observer";
import useScrollPosition from "../../hooks/useScrollPosition";
import ModalBox from "../global/ModalBox";
import { Link } from "react-router-dom";
const Fade = require("react-reveal/Fade");
const Bounce = require("react-reveal/Bounce");

const Websites = () => {
  const mainRef = useRef<HTMLDivElement>(null);

  const content = useBreakpointValue({ base: "flex", sm: "grid" });
  const breakline = useBreakpointValue({ base: "", sm: "\n" });

  return (
    <Box ref={mainRef} h={{ xs: "auto", md: "1850px" }}>
      <Box
        position="absolute"
        mt="5%"
        left="50%"
        display={{ xs: "none", md: "block" }}
        transform="translateX(-50%)"
      >
        <Box h="200px">
          <Heading
            as="h3"
            fontSize="22px"
            textAlign="center"
            fontWeight="700"
            maxW="500px"
          >
            Web development
          </Heading>
          <Heading
            as="h4"
            fontSize="16px"
            textAlign="center"
            fontWeight="500"
            maxW="500px"
            mt="8px"
          >
            Websites, SEO, ERP platforms, E-learning Platforms
          </Heading>
        </Box>
        <Box h="200px">
          <Heading
            as="h3"
            fontSize="22px"
            textAlign="center"
            fontWeight="700"
            maxW="500px"
          >
            Mobile development
          </Heading>
          <Heading
            as="h4"
            fontSize="16px"
            textAlign="center"
            fontWeight="500"
            maxW="500px"
            mt="8px"
          >
            IOS Apps, Andorid Apps, Multiplaform apps
          </Heading>
        </Box>
        <Box h="200px">
          <Heading
            as="h3"
            fontSize="22px"
            textAlign="center"
            fontWeight="700"
            maxW="500px"
          >
            UI/UX Design{" "}
          </Heading>
          <Heading
            as="h4"
            fontSize="16px"
            textAlign="center"
            fontWeight="500"
            maxW="500px"
            mt="8px"
          >
            Full UI/UX Design (.xd, .png, .svg)
          </Heading>
        </Box>
        <Box h="200px">
          <Heading
            as="h3"
            fontSize="22px"
            textAlign="center"
            fontWeight="700"
            maxW="500px"
          >
            Support & Services
          </Heading>
          <Heading
            as="h4"
            fontSize="16px"
            textAlign="center"
            fontWeight="500"
            maxW="500px"
            mt="8px"
          >
            Support Our Partners, Data Science, Cybersecurity, Vector and Raster
            Graphics
          </Heading>
        </Box>

        <Box h="50px" textAlign="center">
          <Link to="/services">
            <Button
              colorScheme="linear-gradient(
              125deg, rgb(244, 0, 118) 25%, rgb(179, 109, 250) 75%);
                  color: white;"
              p="24px 0"
              me={{ xs: "0", sm: "8px" }}
              w="200px"
            >
              🎉 Check this out
            </Button>
          </Link>
        </Box>
      </Box>

      <MacosView
        style={{
          margin: "50px auto",
          position: { xs: "relative", md: "sticky" },
          top: { xs: "0", md: "50vh" },
          transform: "scale(1)",
        }}
      >
        <Heading as="h3" fontSize={{ sm: "32px", base: "28px" }}>
          <Bounce left cascade delay={500}>
            We build websites
          </Bounce>
        </Heading>
        <Fade bottom delay={500}>
          <Heading
            as="h4"
            size="sm"
            mt="12px"
            whiteSpace="pre-wrap"
            color={useColorModeValue("#8B8B8B", "#8B8B8B")}
            fontWeight="400"
            lineHeight="1.45"
          >
            Our goal is to make your websites as simple as we can, {breakline}
            so that users can easily find the things which they want to read
          </Heading>
        </Fade>

        {content === "flex" ? (
          <Flex direction="column" gap={4} mt="36px">
            <Content />
          </Flex>
        ) : (
          <Grid
            templateRows={{
              xs: "repeat(5, 1fr)",
              md: "repeat(4, 1fr)",
              lg: "repeat(2, 1fr)",
            }}
            templateColumns="repeat(6, 1fr)"
            gap={4}
            mt="36px"
          >
            <Content />
          </Grid>
        )}
      </MacosView>
    </Box>
  );
};

// ------------------- WEBSITE MACOS VIEW CARD ----------------------

type WebsiteCardProps = {
  title: string;
  desc: string;
  bigDesc: string | JSX.Element;
  index: number;
  img: string;
  url: string;
};

const WebsiteCard: FC<WebsiteCardProps> = ({
  title,
  desc,
  bigDesc,
  index,
  img,
  url,
}: WebsiteCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <GridItem
      rowSpan={1}
      colSpan={{ xs: 6, md: title === "Full responsive" ? 6 : 3, lg: 2 }}
      className="animGridItem"
    >
      <Fade bottom delay={(index + 1) * 300}>
        <InfoCard>
          <Box>
            <Flex alignItems="center">
              <Image src={img} w="32px" h="32px" mr="12px" />
              <Heading as="h4" fontSize="20px" fontWeight="600">
                {title}
              </Heading>
            </Flex>
            <Text
              fontSize="15.5px"
              fontWeight="400"
              color="#9D9D9D"
              mt="6px"
              whiteSpace="pre-wrap"
            >
              {desc}
            </Text>
            <IosButton
              onClick={() => onOpen()}
              text="Check"
              style={{
                mt: "24px",
                leftIcon: <FiCheck size={24} />,
                colorScheme: "primaryLight",
                color: "primary.500",
              }}
            />
          </Box>
        </InfoCard>
      </Fade>
      <ModalBox
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        body={bigDesc}
        imgUrl={url}
      />
    </GridItem>
  );
};

//=========================================================================

export default Websites;

const Content = () => {
  const { colorMode } = useColorMode();

  const [ref, inView] = useInView({ triggerOnce: true });
  const statsModal = useDisclosure();
  const bussinessModal = useDisclosure();
  return (
    <>
      <WebsiteCard
        index={1}
        img={useColorModeValue(
          "assets/modern_icon_light.svg",
          "assets/modern_icon.svg"
        )}
        title="Modern Websites"
        desc={
          "We build enjoyable, modern websites based on actual UI&UX trends"
        }
        bigDesc={
          <Box fontWeight="600">
            Our websites are modern and always based on the latest trends:
            <br />
            <UnorderedList>
              <ListItem fontSize="16px" fontWeight="400">
                Clean Design
              </ListItem>
              <ListItem fontSize="16px" fontWeight="400">
                Easy to read
              </ListItem>
              <ListItem fontSize="16px" fontWeight="400">
                More images, less text
              </ListItem>
              <ListItem fontSize="16px" fontWeight="400">
                In-style colors
              </ListItem>
            </UnorderedList>
          </Box>
        }
        url={useColorModeValue(
          "assets/ikony/modern_websites_light.svg",
          "assets/ikony/modern_websites_dark.svg"
        )}
      />
      <WebsiteCard
        index={2}
        img={useColorModeValue(
          "assets/design_icon_light.svg",
          "assets/design_icon.svg"
        )}
        title="Best UI Design"
        desc={"We pay attention to the fact that our design is nice and clean"}
        bigDesc={
          "We always make sure that our product design catches up with the latest trends. 77% of agencies believe that a bad user experience in terms of website is a weakness for their clients."
        }
        url={useColorModeValue(
          "assets/ikony/ui_design.svg",
          "assets/ikony/ui_design_dark.svg"
        )}
      />
      <WebsiteCard
        index={3}
        img={useColorModeValue(
          "assets/responsive_icon_light.svg",
          "assets/responsive_icon.svg"
        )}
        title="Fully Responsive"
        desc={
          "Our sites are 100% responsive for the tablets and mobile devices"
        }
        bigDesc={
          "85% of adults think that a company’s website viewed on a mobile should be as good or even better than it’s desktop version."
        }
        url={useColorModeValue(
          "assets/ikony/responsive_light.svg",
          "assets/ikony/responsive_dark.svg"
        )}
      />
      <GridItem rowSpan={1} colSpan={{ xs: 6, lg: 3 }} className="animGridItem">
        <Fade bottom delay={450}>
          <InfoCard>
            <Flex
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Image
                src={
                  colorMode == "dark"
                    ? "assets/stats_dark.svg"
                    : "assets/stats.svg"
                }
                maxH="120px"
              />
              <Box flex="1" ms={{ xs: "0", sm: "32px" }}>
                <Heading
                  as="h4"
                  fontSize="20px"
                  fontWeight="600"
                  mt={{ xs: "16px", sm: "0" }}
                >
                  Expand Your Business
                </Heading>
                <Heading
                  as="h4"
                  fontSize="15.5px"
                  fontWeight="400"
                  lineHeight="1.45"
                  color="#9D9D9D"
                  mt="6px"
                >
                  Nice looking website is a important thing to expand your
                  business
                </Heading>
                <IosButton
                  text="Check"
                  onClick={() => bussinessModal.onOpen()}
                  style={{
                    mt: "24px",
                    leftIcon: <FiCheck size={24} />,
                    colorScheme: "primaryLight",
                    color: "primary.500",
                  }}
                />
              </Box>
            </Flex>
          </InfoCard>
        </Fade>
      </GridItem>
      <GridItem rowSpan={1} colSpan={{ xs: 6, lg: 3 }} className="animGridItem">
        <Fade bottom delay={900}>
          <InfoCard>
            <Flex
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box width="130px" height="130px" ref={ref}>
                <CircularProgressbar
                  value={inView ? 80 : 0}
                  text={`${80}%`}
                  styles={{
                    path: {
                      stroke: `#007AFF`,
                      strokeLinecap: "round",
                      transition: "stroke-dashoffset 3s ease 0s",
                    },
                    trail: {
                      stroke: "transparent",
                      strokeLinecap: "round",
                    },
                    text: {
                      fill: useColorModeValue("#000", "#fff"),
                      fontWeight: "700",
                      fontSize: "16px",
                    },
                  }}
                />
              </Box>
              <Box flex="1" ms={{ xs: "0", sm: "32px" }}>
                <Heading
                  as="h4"
                  fontSize="20px"
                  fontWeight="600"
                  mt={{ xs: "20px", sm: "6px" }}
                >
                  Read Some Stats
                </Heading>
                <Heading
                  as="h4"
                  fontSize="15.5px"
                  fontWeight="400"
                  color="#9D9D9D"
                  lineHeight="1.45"
                  mt="6px"
                >
                  80% of users are angry when a site is too complicated
                </Heading>
                <IosButton
                  text="Check"
                  onClick={() => statsModal.onOpen()}
                  style={{
                    mt: "24px",
                    leftIcon: <FiCheck size={24} />,
                    colorScheme: "primaryLight",
                    color: "primary.500",
                  }}
                />
              </Box>
            </Flex>
          </InfoCard>
        </Fade>

        <ModalBox
          isOpen={bussinessModal.isOpen}
          onClose={bussinessModal.onClose}
          title="Expand Your Bussiness"
          body="With our webistes, ERP Systems or E-Learning platforms you will easily manage your team and company production"
          imgUrl={useColorModeValue(
            "assets/ikony/grow_up.svg",
            "assets/ikony/grow_up_dark.svg"
          )}
        />
        <ModalBox
          isOpen={statsModal.isOpen}
          onClose={statsModal.onClose}
          title="Read Some Stats"
          body={
            <Box fontWeight="600">
              <UnorderedList>
                <ListItem fontSize="16px" fontWeight="400">
                  If given 15 minutes to consume content, two-thirds of people
                  would rather read something beautifully designed than
                  something plain.
                </ListItem>
                <ListItem fontSize="16px" fontWeight="400">
                  A study found that 94% of negative website feedback was design
                  related.
                </ListItem>
                <ListItem fontSize="16px" fontWeight="400">
                  It takes about 50 milliseconds (that’s 0.05 seconds) for users
                  to form an opinion about your website that determines whether
                  they like your site or not, whether they’ll stay or leave.
                </ListItem>
              </UnorderedList>
            </Box>
          }
          imgUrl={useColorModeValue(
            "assets/ikony/some_stats.svg",
            "assets/ikony/some_stats_dark.svg"
          )}
        />
      </GridItem>
    </>
  );
};
