import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Button,
  ModalProps,
  Image,
} from "@chakra-ui/react";
import React, { FC } from "react";

type ModalBoxProps = {
  isOpen: boolean;
  title: string;
  body: string | JSX.Element | JSX.IntrinsicElements;
  style?: ModalProps;
  imgUrl: string;
  onClose: () => void;
};

const ModalBox: FC<ModalBoxProps> = ({
  isOpen,
  onClose,
  title,
  body,
  style,
  imgUrl,
}: ModalBoxProps) => {
  return (
    <Modal
      {...style}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("#eee", "secondary.800")}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody transitionDuration=".3s" fontSize='16px'>
          {" "}
          {imgUrl && (
            <Image
              src={imgUrl}
              margin="70px auto"
              maxWidth="700px"
              width="80%"
            />
          )}
          {body}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="primary" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBox;
