import { Box, Flex, Heading, Link } from "@chakra-ui/react";
import React, { FC } from "react";
import { FiCheck, FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import { MdFacebook, MdSend } from "react-icons/md";
import IosButton from "../global/IosButton";
import MacosView from "../global/MacosView";
import {
  FaInstagram,
  FaLinkedin,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

import { Controller, useForm } from "react-hook-form";
import ContactForm from "../global/ContactForm";
const Contact = () => {
  return (
    <Flex
      mt="48px"
      mb="100px"
      justifyContent="center"
      position="relative"
      direction={{ xs: "column", lg: "row" }}
      alignItems={{ xs: "center", lg: "flex-start" }}
    >
      <Box
        w={{ xs: "100%", lg: "28%", xl: "25%", "2xl": "20%" }}
        position={{ xs: "relative", lg: "absolute" }}
        left={{ xs: "0", lg: "1%", xl: "4%", "2xl": "8.5%" }}
        top={{ xs: "0", lg: "30%" }}
      >
        <MacosView isSearchBar={false} contentPadding="20px">
          <Heading as="h2" fontSize="24px" mt="-8px">
            Contact
          </Heading>
          <Heading
            as="h5"
            fontSize="18px"
            color="secondary.400"
            fontWeight="500"
            mt="4px"
          >
            Get in touch with us
          </Heading>
          <Link
            href="tel:+48 606 27 77 97"
            style={{ textDecorationColor: "transparent" }}
          >
            <IosButton
              text="+48 606 27 77 97"
              style={{
                isFullWidth: true,
                mt: "24px",
                iconSpacing: "8px",
                leftIcon: <FiPhone size={24} />,
                colorScheme: "primaryDark",
                p: "8px 32px",
                color: "primary.500",
              }}
            />
          </Link>
          <Link
            href="mailto:jpiec@nocadis.com"
            isExternal
            style={{ textDecorationColor: "transparent" }}
          >
            <IosButton
              text="jpiec@nocadis.com"
              style={{
                isFullWidth: true,
                mt: "16px",
                iconSpacing: "12px",
                textDecoration: "none !important",
                leftIcon: <FiMail size={24} />,
                colorScheme: "primaryDark",
                p: "8px 32px",
                color: "primary.500",
              }}
            />
          </Link>
          <Link
            isExternal={true}
            href="https://goo.gl/maps/ztoNcBt5ZoEgMU6J6"
            style={{ textDecorationColor: "transparent" }}
          >
            <IosButton
              text="Zambrowska 29 Poznań"
              style={{
                isFullWidth: true,
                mt: "16px",
                iconSpacing: "8px",
                leftIcon: <FiMapPin size={24} />,
                colorScheme: "primaryDark",
                p: "8px 32px",
                color: "primary.500",
              }}
            />
          </Link>
        </MacosView>
      </Box>
      <Box w={{ xs: "100%", lg: "45%" }} mt={{ xs: "24px", lg: "0" }}>
        <MacosView searchBarText="Contact Us">
          <Heading as="h2" mt="8px">
            Contact Form
          </Heading>
          <ContactForm />
        </MacosView>
      </Box>
      <Box
        w={{ xs: "100%", lg: "28%", xl: "25%", "2xl": "20%" }}
        position={{ xs: "relative", lg: "absolute" }}
        right={{ xs: "0", lg: "1%", xl: "4%", "2xl": "8.5%" }}
        top={{ xs: "0", lg: "60%" }}
        mt={{ xs: "24px", lg: "0" }}
      >
        {" "}
        <MacosView isSearchBar={false}>
          <Heading as="h2" fontSize="24px" mt="-8px">
            Find Us
          </Heading>
          <Heading
            as="h5"
            fontSize="18px"
            color="secondary.400"
            fontWeight="500"
            mt="4px"
          >
            Find us on our socials
          </Heading>
          <Flex mt="12px" ml="-8px" alignItems="center" mb="8px">
            <Link href="https://www.instagram.com/nocadisit">
              <FaInstagram
                size={28}
                style={{ margin: "0 8px" }}
                className="icon"
              />
            </Link>
            <Link href="https://www.linkedin.com/company/nocadis-com">
              <FaLinkedin
                size={28}
                style={{ margin: "0 8px" }}
                className="icon"
              />
            </Link>
          </Flex>
        </MacosView>
      </Box>
    </Flex>
  );
};

export default Contact;
