import {
  Box,
  Checkbox,
  Flex,
  Input,
  Textarea,
  useColorModeValue,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdSend } from "react-icons/md";
import IosButton from "./IosButton";
import ModalBox from "./ModalBox";
import PrivacyPolicy from "./PrivacyPolicy";

const Fade = require("react-reveal/Fade");

const ContactForm: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const inputBG = useColorModeValue("#fff", "#232323");

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
      privacyPolicyCheck: false,
    },
  });

  const onSubmit = (data: any) => {
    if (data.name.length === 0) {
      return toast({
        title: "Provide name",
        description: "Please provide your name",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
    if (
      data.email.length === 0 ||
      !String(data.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return toast({
        title: "Provide corrent email",
        description: "Please provide correct email",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
    if (data.phone.length === 0) {
      return toast({
        title: "Provide correct phone number",
        description: "Please correct phone number",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
    if (data.message.length === 0) {
      return toast({
        title: "Provide message",
        description: "Please provide message",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
    if (data.privacyPolicyCheck === false) {
      return toast({
        title: "Accept privacy policy",
        description: "You need to accept privacy policy to send the message",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }

    window.location.href = `mailto:jpiec@nocadis.com?subject=Contact Inquiry&body=name: ${data.name},%0D%0Aemail: ${data.email},%0D%0Aphone number: ${data.phone},%0D%0Amessage: ${data.message}`;

    // const emailBody: { [key: string]: string } = {
    //   "your-name": data.name,
    //   "your-email": data.email,
    //   "your-message": data.message,
    //   tel: data.phone,
    // };
    // const form = new FormData();
    // for (const field in emailBody) {
    //   form.append(field, emailBody[field]);
    // }
    // toast({
    //   title: "Sending message...",
    //   description: "Your message is sending... Please wait",
    //   status: "warning",
    //   duration: 5000,
    //   isClosable: true,
    // });
    // axios
    //   .post(`${process.env.REACT_APP_MAIL_URL}`, form)
    //   .then((response) => {
    //     if (response.data.status === "mail_sent") {
    //       toast({
    //         title: "Message has been sent!",
    //         description: "Your message has been send for us!",
    //         status: "success",
    //         duration: 5000,
    //         isClosable: true,
    //       });
    //       reset({
    //         name: "",
    //         email: "",
    //         phone: "",
    //         message: "",
    //         privacyPolicyCheck: false,
    //       });
    //     } else {
    //       toast({
    //         title: "An Error Occured",
    //         description:
    //           "An Error Occured when sending message. Try again later or contact us another way.",
    //         status: "error",
    //         duration: 5000,
    //         isClosable: true,
    //       });
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     toast({
    //       title: "An Error Occured",
    //       description:
    //         "An Error Occured when sending message. Try again later or contact us another way.",
    //       status: "error",
    //       duration: 5000,
    //       isClosable: true,
    //     });
    //   });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Fade bottom>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                required
                placeholder="Name"
                border="none"
                background={inputBG}
                size={"lg"}
                mt="28px"
                {...field}
              />
            )}
          />
        </Fade>
        <Fade bottom>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                required
                placeholder="Phone Number"
                border="none"
                type="tel"
                background={inputBG}
                size={"lg"}
                mt="16px"
                {...field}
              />
            )}
          />
        </Fade>
        <Fade bottom>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                required
                placeholder="Email"
                type="email"
                border="none"
                background={inputBG}
                size={"lg"}
                mt="16px"
                {...field}
              />
            )}
          />
        </Fade>
        <Fade bottom>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <Textarea
                required
                placeholder="Message"
                resize="none"
                pt="16px"
                border="none"
                background={inputBG}
                size={"lg"}
                height={"200px"}
                mt="16px"
                {...field}
              />
            )}
          />
        </Fade>
        <Fade bottom>
          <Flex mt="20px" ps="4px" alignItems="center">
            <Controller
              name="privacyPolicyCheck"
              control={control}
              defaultValue={false}
              render={({ field: { value, ...field } }) => (
                <Checkbox {...field} isChecked={!!value} size="lg" />
              )}
            />

            <Text ps="12px" color="secondary.300" fontWeight="400" mt="2px">
              By clicking this checkbox your agree with our{" "}
              <span
                onClick={onOpen}
                style={{
                  color: useColorModeValue("#007AFF", "#007AFF"),
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                privacy policy
              </span>
            </Text>
          </Flex>
        </Fade>
        <Fade bottom>
          <IosButton
            text={"Send"}
            style={{
              type: "submit",
              mt: "32px",
              mb: "20px",
              colorScheme: "primary",
              rightIcon: <MdSend size={20} />,
              fontSize: "18px",
              p: "8px 48px",
            }}
          />
        </Fade>
      </Box>
      <ModalBox
        isOpen={isOpen}
        title={"Privacy policy"}
        body={<PrivacyPolicy />}
        imgUrl={""}
        onClose={onClose}
        style={{
          size: "5xl",
          children: null,
          onClose: () => {},
          isOpen: false,
        }}
      />
    </form>
  );
};

export default ContactForm;
