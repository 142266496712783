import {
  Box,
  Button,
  Heading,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import TypewriterComponent from "typewriter-effect";

const ContactUsTemplate = () => {
  const spin2 = keyframes`
    0% { background-position: -15vw }
    25% {  background-position: 0vw}
    50% {  background-position: 15vw }
    75% {background-position: 0vw}
    100% {  background-position: -15vw }
  `;

  const prefersReducedMotion = usePrefersReducedMotion();

  const animation2 = prefersReducedMotion
    ? undefined
    : `${spin2} 10s infinite linear`;

  return (
    <Box
      bg="radial-gradient(circle, rgba(244,0,118,1) 21%, rgba(179,109,250,1) 67%)"
      p="112px 0"
      color={"#fff"}
      animation={animation2}
    >
      <Heading as="h2" textAlign="center">
        Stay in{" "}
        <TypewriterComponent
          options={{
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("Touch")
              .pauseFor(2000)
              .deleteAll()
              .typeString("Contact")
              .pauseFor(2000)
              .start();
          }}
        />
      </Heading>
      <Heading
        as="h4"
        textAlign="center"
        fontSize="20px"
        maxW="700px"
        m="0 auto"
        p='0 30px'
        mt="20px"
      >
        How can we help to create your vision?
      </Heading>
      <Link to='/contact-us'>
        <Button
          variant="outline"
          borderWidth="1px"
          borderColor="#fff"
          m="0 auto"
          _hover={{ bg: "#ffffff44" }}
          display="block"
          mt="32px"
          size={"md"}
        >
          Contact Us
        </Button>
      </Link>
    </Box>
  );
};

export default ContactUsTemplate;
