import React, { FC, ReactNode } from "react";
import { Box, Heading, useColorModeValue } from "@chakra-ui/react";

const Fade = require("react-reveal/Fade");

type TitleProps = {
  title: string;
  subTitle: ReactNode;
  style?: Object;
};

const SectionTitle: FC<TitleProps> = ({
  title,
  subTitle,
  style,
}: TitleProps) => {
  return (
    <Box style={style}>
      <Heading
        as="h1"
        fontSize={{ xs: "24px", sm: "36px", md: "44px" }}
        textAlign="center"
        mt="24px"
        p="0 12px"
        lineHeight="1.3"
        whiteSpace="pre-wrap"
      >
        <Fade bottom delay={300}>
          {title}
        </Fade>
      </Heading>
      <Heading
        as="h2"
        size="sm"
        p="0 12px"
        fontSize={{ xs: "0.9rem", md: "1rem" }}
        textAlign="center"
        mt="20px"
        whiteSpace="pre-wrap"
        color={useColorModeValue("secondary.400", "secondary.300")}
        fontWeight="500"
        lineHeight="1.4"
      >
        <Fade bottom delay={300}>
          {subTitle}
        </Fade>
      </Heading>
    </Box>
  );
};

export default SectionTitle;
