import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Grid,
  Image,
  Select,
  GridItem,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdCopyright, MdFacebook } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
type FooterProps = {
  webDevRef: React.RefObject<HTMLInputElement>;
  mobileDevRef: React.RefObject<HTMLInputElement>;
  servicesRef: React.RefObject<HTMLInputElement>;
};
const Footer: FC<FooterProps> = ({
  webDevRef,
  mobileDevRef,
  servicesRef,
}: FooterProps) => {
  const location = useLocation();

  return (
    <Box bg={useColorModeValue("#EFEFEF", "#262626")}>
      <Grid
        templateColumns={{ xs: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
        p={{ xs: "36px 30px", lg: "36px 44px", xl: "36px 96px" }}
        gap={8}
      >
        <GridItem colSpan={{ xs: 2, lg: 1 }}>
          <Image src="/assets/nocadis_logo.svg" height="50px" />

          <Text
            fontSize="15px"
            color={useColorModeValue("#565656", "#aaa")}
            pt="16px"
            maxW="400px"
          >
            Nocadis is a dedicated team that develops websites, mobile apps, and
            UX/UI designs. We are always eager to create innovative solutions
            and support our partners.
          </Text>
        </GridItem>
        <GridItem colSpan={{ xs: 2, md: 1, xl: 2, lg: 3 }}>
          <Flex
            justifyContent={{ xs: "space-between", lg: "space-evenly" }}
            direction={{ xs: "column", sm: "row" }}
          >
            <Box>
              <Text
                fontWeight="400"
                pt="10px"
                letterSpacing="1px"
                color={useColorModeValue("secondary.500", "secondary.300")}
                pb="12px"
              >
                SOLUTIONS
              </Text>
              <Link
                className="fotterLink"
                to="/"
                onClick={() =>
                  setTimeout(
                    () =>
                      webDevRef.current!.scrollIntoView({ behavior: "smooth" }),
                    location.pathname == "/" ? 0 : 200
                  )
                }
              >
                Web Development
              </Link>
              <Link
                className="fotterLink"
                to="/"
                onClick={() =>
                  setTimeout(
                    () =>
                      mobileDevRef.current!.scrollIntoView({
                        behavior: "smooth",
                      }),
                    location.pathname == "/" ? 0 : 200
                  )
                }
              >
                Mobile Development
              </Link>
              <Link
                className="fotterLink"
                to="/"
                onClick={() =>
                  setTimeout(
                    () =>
                      servicesRef.current!.scrollIntoView({
                        behavior: "smooth",
                      }),
                    location.pathname == "/" ? 0 : 200
                  )
                }
              >
                UI/UX Design
              </Link>
              <Link
                className="fotterLink"
                to="/"
                onClick={() =>
                  setTimeout(
                    () =>
                      servicesRef.current!.scrollIntoView({
                        behavior: "smooth",
                      }),
                    location.pathname == "/" ? 0 : 200
                  )
                }
              >
                Cybersecurity
              </Link>
              <Link
                className="fotterLink"
                to="/"
                onClick={() =>
                  setTimeout(
                    () =>
                      servicesRef.current!.scrollIntoView({
                        behavior: "smooth",
                      }),
                    location.pathname == "/" ? 0 : 200
                  )
                }
              >
                Data Science
              </Link>
            </Box>
            <Box pt={{ xs: "20px", sm: "0" }}>
              <Text
                fontWeight="400"
                pt="10px"
                letterSpacing="1px"
                color={useColorModeValue("secondary.500", "secondary.300")}
                pb="12px"
              >
                COMPANY
              </Text>
              <Link className="fotterLink" to="/about-us">
                About Us
              </Link>
              <Link className="fotterLink" to="/portfolio">
                Portfolio
              </Link>
              <Link className="fotterLink" to="/services">
                Services
              </Link>
            </Box>
            <Box pt={{ xs: "20px", sm: "0" }}>
              <Text
                fontWeight="400"
                pt="10px"
                letterSpacing="1px"
                color={useColorModeValue("secondary.500", "secondary.300")}
                pb="12px"
              >
                HELP
              </Text>
              <Link className="fotterLink" to="/faq">
                FAQ
              </Link>

              <Link to="/contact-us" className="fotterLink">
                Contact Us
              </Link>
            </Box>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Box>
            <Text
              fontWeight="400"
              pt="10px"
              letterSpacing="1px"
              color={useColorModeValue("secondary.500", "secondary.300")}
              pb="12px"
            >
              LANGUAGE
            </Text>
            <Box w={{ xs: "200px", sm: "300px" }}>
              <Select size="lg" colorScheme="primary">
                <option value="option1">🇬🇧 English</option>
                <option value="option2">Coming soon...</option>
              </Select>
            </Box>
          </Box>
        </GridItem>
      </Grid>
      <Flex
        bg={useColorModeValue("#eaeaea", "#2b2b2b")}
        p={{ xs: "24px 30px", lg: "24px 44px", xl: "24px 96px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <MdCopyright size={28} />
          <Text ms="8px" fontWeight="500">
            {new Date().getFullYear()}{" "}
            <span style={{ color: "#007AFF" }}>Nocadis</span>
          </Text>
        </Flex>
        <Flex alignItems="center">
          <a href="https://www.instagram.com/nocadisit">
            <FaInstagram
              size={28}
              style={{ margin: "0 8px" }}
              className="icon"
            />
          </a>
          <a href="https://www.linkedin.com/company/nocadis-com">
            <FaLinkedin
              size={28}
              style={{ margin: "0 8px" }}
              className="icon"
            />
          </a>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
