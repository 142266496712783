import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  keyframes,
  usePrefersReducedMotion,
  useColorModeValue,
  useBreakpointValue,
  calc,
} from "@chakra-ui/react";
import React, { FC, useEffect, useRef } from "react";
import Footer from "../components/global/Footer";
import SectionTitle from "../components/global/SectionTitle";
import TypewriterComponent from "typewriter-effect";
import Tilt from "react-parallax-tilt";
import GoingRow from "../components/AboutUs/GoingRow";
import MacosView from "../components/global/MacosView";
import { ImQuotesRight } from "react-icons/im";
import { FaStar } from "react-icons/fa";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import ContactUsTemplate from "../components/global/ContactUsTemplate";
import { Helmet } from "react-helmet";
const Fade = require("react-reveal/Fade");
const AboutUs = () => {
  const spin = keyframes`
  0% { background-position: -15vw }
  25% {  background-position: 0vw}
  50% {  background-position: 15vw }
  75% {background-position: 0vw}
  100% {  background-position: -15vw }
`;
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} 10s infinite linear`;

  const breakline = useBreakpointValue({ base: "", sm: "\n" });

  return (
    <Box pt={{ xs: "128px", lg: "64px" }}>
      <Helmet>
        <title>Nocadis - About Us</title>
        <meta
          name="description"
          content="Nocadis is a small team developing websites, mobile apps, UX/UI designs and we are always more than happy to create things and support our partners "
        />
      </Helmet>
      <DarkModeSwitch />
      <SectionTitle
        style={{ marginTop: "24px" }}
        title={`We develop web and mobile ${breakline}apps for startups, small ${breakline}and medium business`}
        subTitle={`Nocadis is a small team developing websites, mobile apps, UX/UI designs ${breakline}and we are always more than happy to create things and support our partners`}
      />

      <Box bg={useColorModeValue("#efefef", "#242424")} mt="220px" pb="64px">
        <Flex
          justifyContent="center"
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "flex-start" }}
        >
          <Fade bottom>
            <Tilt
              style={{ marginTop: "-100px" }}
              className="parallax-effect-img"
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              transitionSpeed={1500}
              scale={1.1}
              gyroscope={false}
            >
              <Image
                m={{ xs: "0 auto", md: "0 32px" }}
                mt={{ xs: "30px", md: "-50px" }}
                src="assets/about_us2.jpg"
                h="550px"
                display="block"
                w={{ xs: "90%", md: "350px", lg: "400px" }}
                borderRadius="8px"
                objectFit="cover"
              />
            </Tilt>
          </Fade>
          <Fade delay={500} bottom>
            <Tilt
              className="parallax-effect-img"
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              transitionSpeed={1500}
              scale={1.1}
              gyroscope={false}
            >
              <Image
                m={{ xs: "0 auto", md: "0 32px" }}
                mt={{ xs: "30px", md: "-50px" }}
                src="assets/about_us.jpg"
                h="550px"
                display="block"
                w={{ xs: "90%", md: "350px", lg: "400px" }}
                borderRadius="8px"
                objectFit="cover"
              />
            </Tilt>
          </Fade>
        </Flex>
      </Box>

      <Fade bottom duration={2000}>
        <Heading
          animation={animation}
          w="70%"
          lineHeight="1.3"
          m="0 auto"
          bg="linear-gradient(125deg, rgb(240, 0, 120) 30%, rgb(179, 109, 250) 65%)"
          mt="128px"
          fontSize={{ xs: "28px", md: "40px" }}
          style={{
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          textAlign="center"
        >
          How it's done?
        </Heading>
      </Fade>
      <GoingRow
        style={{ justifyContent: "flex-start" }}
        title="1. The talk"
        image={useColorModeValue("assets/going1.svg", "assets/going1_dark.svg")}
        subTitle={`We conduct a conversation about the \ngeneral outline of the product.`}
      />
      <GoingRow
        style={{ justifyContent: "flex-end" }}
        title="2. Layout"
        image={useColorModeValue("assets/going2.svg", "assets/going2.svg")}
        subTitle={`We create a preliminary layout \nof your product`}
      />
      <GoingRow
        style={{ justifyContent: "flex-start" }}
        title="3. Details"
        image={useColorModeValue("assets/going3.svg", "assets/going3_dark.svg")}
        subTitle={`We talk about the most important \ndetails in your app`}
      />
      <GoingRow
        style={{ justifyContent: "flex-end" }}
        title="4. Feedback"
        image={useColorModeValue("assets/going4.svg", "assets/going4_dark.svg")}
        subTitle={`Already in the process of creating, \nwe ask for your feedback`}
      />
      <GoingRow
        style={{ justifyContent: "flex-start" }}
        title="5. Final"
        image={useColorModeValue("assets/going5.svg", "assets/going5_dark.svg")}
        subTitle={`Finally, you get finished product. \nEnjoy!`}
      />

      {/* <Box bg={useColorModeValue("#efefef", "#242424")} mt="200px" p="64px 0">
        <Fade bottom duration={2000}>
          <Heading
            animation={animation}
            w="70%"
            lineHeight="1.3"
            m="0 auto"
            bg="linear-gradient(125deg, rgb(240, 0, 120) 30%, rgb(179, 109, 250) 65%)"
            textAlign="center"
            fontSize={{ xs: "28px", md: "40px" }}
            style={{
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Our clients says...
          </Heading>
        </Fade>

        <Grid
          w={{ xs: "95%", md: "75%" }}
          m="0 auto"
          pb="32px"
          templateRows={{ xs: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
          templateColumns={{ xs: "repeat(1, 1fr)", md: "repeat(6, 1fr)" }}
          gap={{ xs: 4, xl: 8 }}
        >
          <GridItem
            colSpan={{ xs: 1, md: 3, lg: 2 }}
            mt="64px"
            className="animGridItem"
          >
            <Comment
              index={1}
              name={"Jan Kowalski"}
              content={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. AliquamcomLorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />
          </GridItem>
          <GridItem
            className="animGridItem"
            colSpan={{ xs: 1, md: 3, lg: 4 }}
            mt={{ xs: "0", md: "64px" }}
          >
            <Comment
              index={2}
              name={"Jan Kowalski"}
              content={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. AliquamcomLorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam comLorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />
          </GridItem>
          <GridItem colSpan={{ xs: 1, md: 3, lg: 3 }} className="animGridItem">
            <Comment
              index={3}
              name={"Jan Kowalski"}
              content={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. AliquamcomLorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />
          </GridItem>
          <GridItem colSpan={{ xs: 1, md: 3, lg: 3 }} className="animGridItem">
            <Comment
              index={4}
              name={"Jan Kowalski"}
              content={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. AliquamcomLorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />
          </GridItem>
        </Grid>
      </Box> */}
      <ContactUsTemplate />
    </Box>
  );
};

export default AboutUs;

type CommentProps = {
  name: string;
  content: string;
  index: number;
};

const Comment: FC<CommentProps> = ({ name, content, index }: CommentProps) => {
  return (
    <Fade bottom delay={index * 400}>
      <MacosView
        isSearchBar={false}
        style={{ height: "100%", w: "100%" }}
        contentStyle={{
          h: `calc(100% - 32px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Flex alignItems="center">
          <ImQuotesRight size={32} />
          <Text fontWeight="700" ms="20px" mt="4px" fontSize="20px">
            {name}
          </Text>
        </Flex>
        <Text fontWeight="500" mt="16px" fontSize="15px" flex="1">
          {content}
        </Text>
        <Flex mt="20px">
          <FaStar size={24} color="#FFCE00" style={{ marginRight: "3px" }} />
          <FaStar size={24} color="#FFCE00" style={{ margin: "0 3px" }} />
          <FaStar size={24} color="#FFCE00" style={{ margin: "0 3px" }} />
          <FaStar size={24} color="#FFCE00" style={{ margin: "0 3px" }} />
          <FaStar size={24} color="#FFCE00" style={{ margin: "0 3px" }} />
        </Flex>
      </MacosView>
    </Fade>
  );
};
