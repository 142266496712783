import {
  Box,
  BoxProps,
  Divider,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { FiRefreshCw, FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { MdCloseFullscreen, MdMinimize } from "react-icons/md";
import { VscChromeMinimize } from "react-icons/vsc";
import { CgArrowsScrollV } from "react-icons/cg";
type OperateIconProps = {
  color: string;
};

type MacosViewProps = {
  style?: BoxProps;
  contentStyle?: BoxProps;
  isSearchBar?: boolean;
  contentPadding?: string;
  searchBarText?: string;
  children?: JSX.Element | JSX.Element[] | string | string[];
};

const MacosView: FC<MacosViewProps> = ({
  style,
  isSearchBar = true,
  contentStyle,
  searchBarText = "nocadis.com",
  contentPadding = "36px",
  children,
}: MacosViewProps) => {
  const searchBarColor = useColorModeValue("#E0E0E0", "#222");
  return (
    <Box
      bg={useColorModeValue("#efefef", "#303030")}
      border={`1px solid ${useColorModeValue("#8B8B8B", "#777")}`}
      borderRadius="20px"
      width={{ xs: "95%", lg: "100%" }}
      m="0 auto"
      p="20px 0"
      maxW="1200px"
      {...style}
    >
      <Box p="0 20px">
        <Flex pb={isSearchBar ? "0" : "8px"}>
          <OperaIcon color="#EF6559" />
          <OperaIcon color="#ffc800" />
          <OperaIcon color="#5CC23A" />
        </Flex>

        {isSearchBar && (
          <Flex mt="12px">
            <Flex alignItems="center">
              <FiArrowLeft size={24} style={{ marginRight: "1px" }} />
              <FiArrowRight size={24} style={{ marginLeft: "1px" }} />
              <FiRefreshCw size={23} style={{ marginLeft: "12px" }} />
            </Flex>

            <Box
              flex="1"
              bg={searchBarColor}
              ml="20px"
              p="4px 20px"
              fontSize="14px"
              borderRadius="full"
            >
              {searchBarText}
            </Box>
          </Flex>
        )}
      </Box>

      <Divider
        bg={useColorModeValue("#8B8B8B", "#777")}
        opacity="1"
        mt="10px"
      />

      <Box
        pt="40px"
        ps={{ xs: "20px", md: contentPadding }}
        pe={{ xs: "20px", md: contentPadding }}
        {...contentStyle}
      >
        {children}
      </Box>
    </Box>
  );
};

const OperaIcon: FC<OperateIconProps> = ({ color }: OperateIconProps) => {
  return (
    <Box
      w="13px"
      h="13px"
      bg={color}
      borderRadius="full"
      m="0 3px"
      role="group"
    >
      <Box
        w="13px"
        h="13px"
        display="none"
        _groupHover={{
          display: "block",
        }}
      >
        {color === "#EF6559" ? (
          <IoCloseOutline color="#000" size="auto" />
        ) : color === "#ffc800" ? (
          <VscChromeMinimize color="#000" size="12px" />
        ) : (
          <CgArrowsScrollV
            style={{ transform: "rotate(-45deg)" }}
            height="auto"
            width="auto"
            color="#000"
            size="auto"
          />
        )}
      </Box>
    </Box>
  );
};
export default MacosView;
