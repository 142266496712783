import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  keyframes,
  useBreakpointValue,
  usePrefersReducedMotion,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { FC } from "react";
import DarkModeSwitch from "../components/global/DarkModeSwitch";
import SectionTitle from "../components/global/SectionTitle";
import "./iphone_style.css";
import { GiClick } from "react-icons/gi";
import Footer from "../components/global/Footer";
import ContactUsTemplate from "../components/global/ContactUsTemplate";
import { Helmet } from "react-helmet";
const Fade = require("react-reveal/Fade");

const Services = () => {
  const breakline = useBreakpointValue({ base: "", sm: "\n" });
  const alignText = useBreakpointValue<string>({ base: "center", md: "left" });
  return (
    <Box>
      <Helmet>
        <title> - Services</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <Box pt={{ xs: "128px", lg: "64px" }} mb="100px">
        <Fade bottom>
          <DarkModeSwitch />
        </Fade>
        <SectionTitle
          title={`Our Services`}
          subTitle={`We are developing websites, mobile apps, UX/UI designs and more. ${breakline}We are always more than happy to create things and support our partners.
          `}
        />

        <Flex
          justifyContent="center"
          mt="128px"
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
        >
          <Fade left>
            <Mockup
              title={"Websites"}
              url={"/services"}
              desc={"We build nifty websites and web apps"}
            />
          </Fade>
          <Fade bottom>
            <Box p={{ xs: "30px 0", md: "0 88px" }}>
              <Tooltip label="Hover to see more!" aria-label="A tooltip">
                <Box
                  borderRadius="full"
                  border={`2px solid ${useColorModeValue("#000", "#fff")}`}
                  padding="16px"
                  _hover={{ transform: "rotate(30deg)" }}
                  transitionDuration=".3s"
                  display={{ xs: "none", md: "block" }}
                >
                  <GiClick size={48} />
                </Box>
              </Tooltip>
            </Box>
          </Fade>
          <Fade right>
            <Mockup
              title={"Mobile Apps"}
              url={"/services"}
              desc={"We build multiplatform mobile apps"}
            />
          </Fade>
        </Flex>
        <SectionTitle
          style={{ marginTop: "128px" }}
          title={`Over the years we have \ngained experience in…`}
          subTitle={`Last few months were crazy for us ${breakline}but we are more than ready to create your dreams.`}
        />
        <Flex
          justifyContent="space-evenly"
          w={{ xs: "95%", md: "70%" }}
          m="48px auto"
          direction={{ xs: "column", md: "row" }}
        >
          <ul
            role="list"
            style={{
              listStyleType: "none",
              textAlign: alignText == "center" ? "center" : "left",
            }}
          >
            <li data-icon="🚀 ">
              <Tooltip label="Extract knowledge and insights from noisy, structured and unstructured data">
                Data Science
              </Tooltip>
            </li>
            <li data-icon="🔥 " style={{ marginTop: "20px" }}>
              <Tooltip label="The best designs">UX/UI Design</Tooltip>
            </li>
          </ul>
          <ul
            role="list"
            style={{
              listStyleType: "none",
              textAlign: alignText == "center" ? "center" : "left",
              marginTop: alignText == "center" ? "20px" : "0",
            }}
          >
            <li data-icon="💎 ">
              <Tooltip label="Make your softwares always secure">
                Cybersecurity
              </Tooltip>
            </li>
            <li data-icon="😎 " style={{ marginTop: "20px" }}>
              <Tooltip label="User friendly graphic illustrations">
                Graphics Things
              </Tooltip>
            </li>
          </ul>
          <ul
            role="list"
            style={{
              listStyleType: "none",

              textAlign: alignText == "center" ? "center" : "left",
              marginTop: alignText == "center" ? "20px" : "0",
            }}
          >
            <li data-icon="🖥️ ">
              <Tooltip label="ERP And E-Learning Solutions">
                ERP & E-learning
              </Tooltip>
            </li>
            <li data-icon="📈 " style={{ marginTop: "20px" }}>
              <Tooltip label="SEO - Always be on the top">SEO</Tooltip>
            </li>
          </ul>
        </Flex>
      </Box>
      <ContactUsTemplate />
    </Box>
  );
};

export default Services;

type MockupProps = {
  title: String;
  url: String;
  desc: String;
};

const Mockup: FC<MockupProps> = ({ title, url, desc }) => {
  const spin = keyframes`
  0% { background-position: -12px }
  25% {  background-position: 0}
  50% {  background-position: 12px } 
  75% {background-position: 0}
  100% {  background-position: -12px }
`;

  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} 10s infinite linear`;
  return (
    <Box className="iphonex">
      <Box className="front">
        <Box className="screen">
          <Box className="screen__view">
            <Box className="contentCenterImg">
              <Heading
                animation={animation}
                w="90%"
                m="0 auto"
                bg="linear-gradient(125deg, rgb(240, 0, 120) 30%, rgb(179, 109, 250) 65%)"
                style={{
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                textAlign="center"
              >
                {title}
              </Heading>
            </Box>
            <Box className="content">
              <Heading textAlign="center" fontSize="24px" p="0 32px">
                {desc}
              </Heading>
              <a href="/portfolio">
                <Button
                  variant="outline"
                  borderWidth="1px"
                  borderColor="#fff"
                  m="0 auto"
                  _hover={{ bg: "#ffffff44" }}
                  display="block"
                  _active={{
                    bg: "#ffffff55",
                    transform: "scale(0.98)",
                  }}
                  mt="32px"
                  size={"md"}
                >
                  Check this out
                </Button>
              </a>
            </Box>
          </Box>
          <Box className="screen__front">
            <Box className="screen__front-speaker"></Box>
            <Box className="screen__front-camera"></Box>
          </Box>
        </Box>
        <Box className="front__line"></Box>
        <Box className="front__line front__line-second"></Box>
      </Box>
      <Box className="phoneButtons phoneButtons-right"></Box>
      <Box className="phoneButtons phoneButtons-left"></Box>
      <Box className="phoneButtons phoneButtons-left2"></Box>
      <Box className="phoneButtons phoneButtons-left3"></Box>
    </Box>
  );
};
